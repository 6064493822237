<template>
 <div>
  <svg viewBox="0 0 183 183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="userSpaceOnUse" id="Naamloos_verloop_8" x1="155.9" x2="30.37" y1="65.68" y2="111.37">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g data-name="Laag 2" id="Laag_2">
    <g data-name="Laag 1" id="Laag_1-2">
     <rect :fill="background" height="183" width="183"/>
     <path d="M121.12,36H48.79a7.82,7.82,0,0,0-7.8,7.8v95.36a7.82,7.82,0,0,0,7.8,7.8h72.33a7.82,7.82,0,0,0,7.8-7.8V127l-7.65,7.62-1,1-1.4.32-17.24,4-.3.07-.32,0a7.46,7.46,0,0,1-1,.05l-.71,0a9.75,9.75,0,0,1-1.7-.21,9.32,9.32,0,0,1-7.1-11.08v-.06l4-17.14.33-1.39,1-1,33-33,.09-.09V43.82A7.82,7.82,0,0,0,121.12,36ZM59.54,54.83H99.87a3,3,0,0,1,0,6H59.54a3,3,0,0,1,0-6ZM93.19,91.5H59.29a2.75,2.75,0,1,1,0-5.5h33.9a2.75,2.75,0,1,1,0,5.5Zm15.52-14.56H59.84a3.3,3.3,0,0,1,0-6.6h48.87a3.3,3.3,0,1,1,0,6.6ZM95.42,129.81a4.31,4.31,0,0,0,3.29,5.12,4.41,4.41,0,0,0,.89.1l.45,0,.45,0,17.24-4,11.18-11.14V83.11L99.39,112.67Zm37-50.15-3.45,3.45L99.39,112.67l-4,17.14a4.31,4.31,0,0,0,3.29,5.12,4.41,4.41,0,0,0,.89.1l.45,0,.45,0,17.24-4,11.18-11.14,21.92-21.83Zm28.88,1.92L148.92,69.25a4.32,4.32,0,0,0-6.1,0L136,76.1l18.45,18.45,6.86-6.85a4.35,4.35,0,0,0,0-6.12Z" style="fill:url(#Naamloos_verloop_8);"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>